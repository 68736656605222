<template>
  <el-image class="zt" v-for="url in urls" :key="url" :src="url" :lazy="false" />
  <div class="ba">Copyright @ 2024 武汉市轩宇泽网络科技有限公司 <a target="_blank" href="https://beian.miit.gov.cn/" style="margin-left: 10px;color: gray;">鄂ICP备2024045330号-1</a></div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      urls: [
        'https://erp-image-oss.oss-cn-hangzhou.aliyuncs.com/xyz/1.png',
        'https://erp-image-oss.oss-cn-hangzhou.aliyuncs.com/xyz/2.png',
        'https://erp-image-oss.oss-cn-hangzhou.aliyuncs.com/xyz/3.png',
        'https://erp-image-oss.oss-cn-hangzhou.aliyuncs.com/xyz/4.png',
        'https://erp-image-oss.oss-cn-hangzhou.aliyuncs.com/xyz/5.png',
        'https://erp-image-oss.oss-cn-hangzhou.aliyuncs.com/xyz/6.png',
      ]
   }
 }
}
</script>

<style>
html{
  padding: 0;
  margin: 0;
}
body{
  padding: 0;
  margin: 0;
}
.app{
  padding: 0;
  margin: 0;
}
.zt{
  width: 100%;
}
.el-image{
  overflow: unset;
}
.ba{
  height: 60px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 13px;
}
</style>
